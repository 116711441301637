import React from 'react'
import { CardMedia } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Element } from 'react-scroll';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

import Text from '../../../templates/Text'


export default function Main({ data, dress }) {

  return (
    <Element name=''>
      <div className='home_images_wrapper'>
        <Slider
          infinite={true}
          autoplay={data.autoplay.images}
          touchDisabled={true}
          previousButton={data.images.length > 1 && <KeyboardArrowLeft style={{ color: '#a80000' }} />}
          nextButton={data.images.length > 1 && <KeyboardArrowRight style={{ color: '#a80000' }} />}
        >
          {data.images.map((item, index) => (
            <CardMedia
              key={index}
              image={item}
            />
          ))}
        </Slider>
        <img className="home_dress" alt="dress" src={data.dresses[dress]} />
      </div>
      <div className="app_padding">
        <Text text={data.text} />
      </div>
    </Element>
  )
}