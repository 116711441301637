import React from 'react'
import { Translate as T } from 'react-redux-i18n'
import { Element } from 'react-scroll';
import {
    Button,
    Grid,
    Typography
} from '@material-ui/core';

import Text from '../../../templates/Text'


export default function Courses({ data }) {

    return (
        <Element name='boerse' className='app_padding'>
            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Text text={data.text} />
                </Grid>
                {data.available_dates > 0 ?
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        <br /><br />
                        <Typography variant='h3'>{data.available_dates}</Typography>
                        <Typography variant='subtitle1'><T value='boerse.available_dates' /></Typography>
                        <br />
                        <Button onClick={() => window.location.hash = '/boerse'} color='secondary'><T value='boerse.discover_more' /></Button>
                    </Grid>
                    :
                    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '0 50px' }}>
                        <br /><br />
                        <Typography variant='h3' style={{ color: '#a9a9a9' }}>0</Typography>
                        <Typography variant='subtitle1'><T value='boerse.no_available_dates' /></Typography>
                        <br /><br />
                    </Grid>
                }
            </Grid>
        </Element>
    )
}