import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from '../../utils/actions'
import { withFirebase } from '../../utils/firebase'
import {
  Box,
  Divider,
} from '@material-ui/core'

import Main from './elements/Main'
import Courses from './elements/Courses'
import Atelier from './elements/Atelier'
import Boerse from './elements/Boerse'
//import AboutMe from './elements/AboutMe'
import Loader from '../../templates/Loader'



class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true,
      active_dress_index: 0,
      scrolltoId: 'top'
    }
  }

  componentDidMount() {
    this.props.firebase.getStatic({ key: 'home' })
      .then((data) => this.setState({
        loading: false,
        data,
        active_dress_index: this._getRandom(data.main.dresses.length - 1)
      }))
      .then(() => this._setDressInterval())
      .catch((e) => this.props._setError(e))
  }

  componentWillUnmount() {
    clearInterval(this.dressInterval)
  }

  _setDressInterval() {
    const { dresses, autoplay } = this.state.data.main
    const max_dress_index = dresses.length - 1

    this.dressInterval = setInterval(function () {
      const { active_dress_index } = this.state
      let next_dress_index = active_dress_index + 1

      this.setState({
        active_dress_index: next_dress_index > max_dress_index ? 0 : next_dress_index
      })
    }.bind(this), autoplay.dresses)
  }

  _getRandom(max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  render() {
    const {
      data,
      loading,
      active_dress_index,
    } = this.state

    if (!data || loading) {
      return (<Loader />)
    }

    return (
      <Box className='app_content'>
        <Main data={data.main} dress={active_dress_index} />
        <Divider className='app_divider' />
        <Courses data={data.courses} />
        <Divider className='app_divider' />
        <Atelier data={data.atelier} />
        <Divider className='app_divider' />
        <Boerse data={data.boerse} />
      </Box >
    )
  }
}

const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error))
})

export default withFirebase(connect(null, mapDispatchToProps)(Home))