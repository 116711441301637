export default {
    app: {
        error: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.',
        price_unit: 'CHF',
        time_unit: 'Uhr',
        nodates: 'Keine Kursdaten Verfügbar',
        ok: 'Verstanden'
    },
    home: {
        title: 'Home',
        discover_more: 'Mehr Erfahren',
        email: 'Email',
        facebook: 'Facebook',
        instagram: 'Instagram'
    },
    courses: {
        title: 'Kursangebot',
        short: 'Kürzel',
        naehkurs: 'Nähkurs',
        themenkurs: 'Themenkurs',
        course_dates: 'Kursdaten',
        date: 'Datum',
        dates: 'Daten',
        price: 'Preis',
        note: 'Bemerkung',
        mat_inkl: 'Inklusiv Material',
        mat_not_inkl: 'Exklusiv Material',
        full: 'Ausgebucht',
        no_available_dates: 'Momentan sind keine Kursdaten verfügbar.'
    },
    signup: {
        title: 'Anmeldung',
        course: 'Kurs',
        personal_details: 'Personalien',
        summary: 'Abschliessen',
        continue: 'Weiter',
        back: 'Zurück',
        complete: 'Anmelden',
        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'Email',
        phone: 'Telefonnummer',
        street: 'Strasse Nr.',
        zipcode: 'Postleitzahl',
        town: 'Stadt',
        agb: 'Anmeldebedingungen',
        back_to_home: 'Zurück zur Startseite',
        reference: 'Buchungsreferenz',
        signup_invalid: 'Die eingegebenen Daten konnten nicht validiert werden.',
        course_full: 'Dieser Kurs hat bereits die maximale Anzahl anmeldungen erreicht.'
    },
    atelier: {
        title: 'Offenes Atelier'
    },
    boerse: {
        title: 'Kursbörse',
        available_dates: 'Verfügbare Kursdaten',
        no_available_dates: 'Momentan sind keine Kursdaten in der Kursbörse verfügbar.',
        discover_more: 'Zur Anmeldung',
        timeframe: 'Uhrzeit'
    },
    aboutme: {
        title: 'Über Mich'
    },
    gallery: {
        title: 'Galerie'
    },
    contact: {
        title: 'Kontakt',
        message: 'Nachricht',
        send: 'Nachricht senden',
        address: 'Adresse'
    },
    footer: {
        impressum: 'Impressum',
        datenschutz: 'Datenschutz'
    },
    user: {
        login: 'Login',
        signup: 'Anmelden'
    },
    notfound: {
        title: 'Die Seite konnte nicht gefunden werden.',
        back_to_home: 'Zurück zur Startseite',
    }
}