import React from 'react'
import { Grid } from '@material-ui/core'
import { Element } from 'react-scroll';

import Text from '../../../templates/Text'


export default function Courses({ data }) {

    return (
        <Element name='atelier' className='app_padding'>
            <Grid container spacing={4} direction="row" justify="space-evenly" alignItems="flex-start" className='home_grid' >
                <Grid item xs={12} md={12} >
                    <Text text={data.text} />
                </Grid>
            </Grid>
        </Element>
    )
}