import {
  SET_ERROR,
  RESET_ERROR
} from '../actions';

const initialState = {
  error: null
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      console.error(action.error)
      return {
        error: action.error
      }
    case RESET_ERROR:
      return {
        error: null
      }
    default:
      return state
  }
}

export default rootReducer;