import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from '../../utils/actions'
import { withFirebase } from '../../utils/firebase'
import { Translate as T } from 'react-redux-i18n'
import './Footer.css'
import {
  Button, Grid, Divider, Link
} from '@material-ui/core'
import {
  Instagram,
  Facebook,
  MailOutline,
  Explore
} from '@material-ui/icons';
import Text from '../../templates/Text';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: null
    }
  }

  componentDidMount() {
    this.props.firebase.getStatic({ key: 'footer' })
      .then((data) => this.setState({
        loading: false,
        data
      }))
      .catch((error) => this.props._setError(error))
  }

  render() {
    const { data } = this.state

    return (
      <div className='footer app_padding'>
        <div className='app_width'>
          {data && <Grid container>
            <Grid item xs={12} sm={9} >
              <Button
                size="large"
                color="primary"
                startIcon={<Instagram />}
                onClick={() => window.open(data.instagram, '_blank')}
              >
                <T value="home.instagram" />
              </Button>
              <Button
                size="large"
                color="primary"
                startIcon={<Facebook />}
                onClick={() => window.open(data.facebook, '_blank')}
              >
                <T value="home.facebook" />
              </Button>
              <Button
                size="large"
                color="primary"
                startIcon={<MailOutline />}
                href='#/contact'
              >
                <T value="home.email" />
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
              <Button
                size="large"
                color="primary"
                startIcon={<Explore />}
                onClick={() => window.open('https://www.bastella.ch', '_blank')}
              >
                BASTELLA
              </Button>
            </Grid>
            <Grid item xs={12}>
              <br /><Divider style={{ backgroundColor: '#942c2c' }} /><br />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Text text={data.address} />
              <br />
            </Grid>
            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
              <Link href='#/impressum'>
                <T value="footer.impressum" />
              </Link>
              <br />
              <Link href='#/datenschutz'>
                <T value="footer.datenschutz" />
              </Link>
            </Grid>
          </Grid>
          }
        </div>
      </div >
    )
  }
}

const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error))
})

export default withFirebase(connect(null, mapDispatchToProps)(Footer))