import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { Element } from 'react-scroll';
import { Translate as T } from 'react-redux-i18n'

import Text from '../../../templates/Text'


export default function Courses({ data }) {
    return (
        <Element name='courses' className='app_padding'>
            <Grid container spacing={4} direction="row" justify="space-evenly" alignItems="flex-start" className='home_grid' >
                <Grid item xs={12}>
                    <Typography variant="h6" component="p"><T value="courses.title" /></Typography>
                </Grid>
                {data.map((course, key) => (
                    <Grid item xs={12} md={6} key={key}>
                        <Text text={course.text} />
                        <br />
                        <Button
                            color="secondary"
                            className='home_course_button'
                            onClick={() => window.location.hash = course.hash}
                        >
                            <T value='home.discover_more' />
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Element>
    )
}