import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n'
import translationsObject from '../i18n'
import reducers from '../reducers'

const store = createStore(
    combineReducers({
        data: reducers,
        i18n: i18nReducer
    }),
    applyMiddleware(thunk, logger)
)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject))

let locale = window.localStorage.getItem('_lang') || 'de'
store.dispatch(setLocale(locale))


export default store