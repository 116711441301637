import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/database'

const config = {
  prod: {
    apiKey: "AIzaSyBPHaDWKDzK-QRcHciFZv2VdpqwrE1ZqPI",
    authDomain: "fadenwerk-murten.firebaseapp.com",
    databaseURL: "https://fadenwerk-murten.firebaseio.com",
    projectId: "fadenwerk-murten",
    storageBucket: "fadenwerk-murten.appspot.com",
    messagingSenderId: "938211292732",
    appId: "1:938211292732:web:50bc48837e1909fe5a3539",
    measurementId: "G-WLJCF4L00Z"
  },
  beta: {
    apiKey: "AIzaSyCR92X-NsJ146JFNKacvQINZfAkpiATChU",
    authDomain: "fadenwerk-murten-beta-e0194.firebaseapp.com",
    databaseURL: "https://fadenwerk-murten-beta-e0194.firebaseio.com",
    projectId: "fadenwerk-murten-beta-e0194",
    storageBucket: "fadenwerk-murten-beta-e0194.appspot.com",
    messagingSenderId: "352174798615",
    appId: "1:352174798615:web:48434a300e54b597f16952",
    measurementId: "G-WQR2J4ST2E"
  }
}

class Firebase {
  constructor() {
    app.initializeApp(config.beta);

    this.database = app.database();
    this.firestore = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics();

    this.state = {
      updates: null,
      static_data: {}
    }
  }

  // get static data
  getStatic({ key, subkey }) {
    return new Promise(function (resolve, reject) {
      const static_data = this.state.static_data

      if (subkey) {
        if (static_data[key] && static_data[key][subkey]) {
          this.analytics.logEvent('_page_load', { page_key: key, cache: true })
          return resolve(static_data[key][subkey])
        }
      } else {
        if (static_data[key]) {
          if (key !== 'home') {
            this.analytics.logEvent('_page_load', { page_key: key, cache: true })
            return resolve(static_data[key])

          } else if (static_data[key].main) {
            this.analytics.logEvent('_page_load', { page_key: key, cache: true })
            return resolve(static_data[key])
          }
        }
      }

      const path = 'static/' + key + (subkey ? '/' + subkey : '/')

      this.database.ref(path).once('value')
        .then((snap) => {
          const data = snap.val()
          this.state.static_data[key] = data
          this.analytics.logEvent('_page_load', { page_key: key, cache: false })
          resolve(data)
        })
        .catch((e) => reject(e))
    }.bind(this))
  }

  paths = {
    courses: 'courses',
    seasons: 'seasons',
    dates: 'dates'
  }

  ref_courses = () => this.firestore.collection(this.paths.courses)
  ref_seasons = (courseId) => this.firestore.collection(`${this.paths.courses}/${courseId}/${this.paths.seasons}`)
  ref_dates = (courseId, seasonId) => this.firestore.collection(`${this.paths.courses}/${courseId}/${this.paths.seasons}/${seasonId}/${this.paths.dates}`)
  ref_dates_grp = () => this.firestore.collectionGroup(this.paths.dates)

  getCourse(id) {
    return new Promise(function (resolve, reject) {
      this.ref_courses()
        .doc(id)
        .get()
        .then((doc) => resolve(this.__doc_to_json(doc)))
        .catch((error) => reject(error))
    }.bind(this))
  }

  getCourses(type) {
    return new Promise(function (resolve, reject) {
      this.ref_courses()
        .where('type', '==', type)
        .orderBy('index', 'asc')
        .get()
        .then((snap) => resolve(snap.docs.map((doc) => this.__doc_to_json(doc))))
        .catch((error) => reject(error))
    }.bind(this))
  }

  getSeason(courseId, seasonId) {
    return new Promise(function (resolve, reject) {
      this.ref_seasons(courseId)
        .doc(seasonId)
        .get()
        .then((doc) => resolve(this.__doc_to_json(doc)))
        .catch((error) => reject(error))
    }.bind(this))
  }

  getSeasons(courseId) {
    return new Promise((resolve, reject) => this.ref_seasons(courseId)
      .where('closed', '==', false)
      .where('locked', '==', false)
      .orderBy('startdate', 'asc')
      .get()
      .then((snap) => {
        resolve(snap.docs.map(doc => {
          return this.__doc_to_json(doc)
        }))
      })
      .catch((e) => reject(e)))
  }


  getSeasonsBeorse() {
    return new Promise((resolve, reject) => this.ref_seasons('boerse')
      .where('full', '==', false)
      .where('closed', '==', false)
      .where('locked', '==', false)
      .orderBy('startdate', 'asc')
      .get()
      .then((snap) => {
        resolve(snap.docs.map(doc => {
          return this.__doc_to_json(doc)
        }))
      })
      .catch((e) => reject(e)))
  }

  __doc_to_json(doc) {
    if (!doc.exists) {
      return {}
    }
    const data = doc.data()
    data._id = doc.id
    data._path = doc.ref.path
    return data
  }

  // add new signup to firestore
  setSignUp(signup) {
    return new Promise(function (resolve, reject) {
      if (this._validateSignUp(signup) && this._validateUser(signup.user)) {
        this.analytics.logEvent('_course_signup', {
          userId: signup.userId,
          courseId: signup.courseId,
          language: signup.language,
          type: signup.type
        })
        this.firestore.collection('signups').add(signup)
          .then(({ id }) => {
            signup._id = id
            resolve(signup)
          })
          .catch((error) => reject(error))
      } else {
        reject('SignUp not valid')
      }
    }.bind(this))
  }

  getSignUp(signupId) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('signups')
        .doc(signupId)
        .get()
        .then((doc) => resolve(this.__doc_to_json(doc)))
        .catch((error) => reject(error))
    })
  }

  _validateUser(object) {
    return (
      object.hasOwnProperty('firstname') &&
      object.hasOwnProperty('lastname') &&
      object.hasOwnProperty('email') &&
      object.hasOwnProperty('street') &&
      object.hasOwnProperty('zipcode') &&
      object.hasOwnProperty('town')
    )
  }

  _validateSignUp(object) {
    return (
      object.hasOwnProperty('courseId') &&
      object.hasOwnProperty('seasonId') &&
      object.hasOwnProperty('missoutdate') &&
      object.hasOwnProperty('missoutdateId') &&
      object.hasOwnProperty('language') &&
      object.hasOwnProperty('type') &&
      object.hasOwnProperty('timestamp')
    )
  }

  _updateCourseBoerseAvailableDates(type) {
    try {
      let available_dates = this.state.static_data.home.boerse.available_dates

      switch (type) {
        case 'add':
          available_dates = available_dates + 1
          break;
        case 'remove':
          if (available_dates - 1 >= 0) {
            available_dates = available_dates - 1
          }
          break;
      }
      this.state.static_data.home.boerse.available_dates = available_dates

    } catch (e) {
      console.error(e)
    }
  }
}

export default Firebase