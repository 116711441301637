import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Collapse,
  Button,
  Typography,
  Popper,
  List,
  ListItem
} from '@material-ui/core';
import {
  Menu as MenuIcon
} from '@material-ui/icons'
import LanguageSelect from '../../components/LanguageSelect'
import FadenwerkLogo from '../../assets/images/logo_500x200.png'


export default function CustomAppBar(props) {

  const is_top = props.scrollposition < 0.05
  const is_small = props.width < 800

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    if (props.popper_open) {
      handleClose()
      window.location.hash = 'home/courses'
    } else {
      setAnchorEl(event.currentTarget)
      props.toggle_popper()
    }
  }

  const handleClose = () => {
    props.toggle_popper()
  }

  return (
    <AppBar position="fixed" style={{ backgroundColor: 'white', color: 'black' }}>
      <div className='app_width'>

        {true && <div className='beta_wrapper'>
          BETA
        </div>}
        {is_small &&
          <Toolbar>
            <IconButton onClick={props.toggle_drawer}>
              <MenuIcon />
            </IconButton>
            {is_top && <LanguageSelect />}
          </Toolbar>
        }

        <Collapse in={is_top} timeout="auto" unmountOnExit>
          {!is_small &&
            <Toolbar>
              <Typography value='' className='nav_toolbar_spacer' />
              {false && <Button
                color='primary'
              >
                <T value='user.login' />
              </Button>}
              <LanguageSelect />
            </Toolbar>
          }
          <img className='nav_logo'
            onClick={() => window.location.hash = 'home'}
            alt="logo"
            src={FadenwerkLogo}
          />
        </Collapse>
        {!is_small &&
          <Tabs
            value={props.selected_index}
            indicatorColor="secondary"
            centered
          >
            {[
              ['home', 'home']
            ].map((item) => (
              <Tab key={item} className='nav_tab' component='a' href={'#' + item[1]} label={<T value={item[0] + '.title'} />} />
            ))}
            <Tab className='nav_tab' component='a' onClick={handleClick} label={<T value={'courses.title'} />} />
            {[
              ['atelier', 'home/atelier'],
              ['boerse', 'boerse'],
              ['aboutme', 'aboutme'],
              ['gallery', 'gallery'],
              ['contact', 'contact']
            ].map((item) => (
              <Tab key={item} className='nav_tab' component='a' href={'#' + item[1]} label={<T value={item[0] + '.title'} />} />
            ))}

          </Tabs>
        }
      </div>


      <Popper
        anchorEl={anchorEl}
        open={props.popper_open}
        onClose={props.toggle_popper}
        onMouseLeave={props.toggle_popper}
      >
        <List style={{ backgroundColor: 'white', borderRadius: '0px 0px 4px 4px', boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)' }}>
          <ListItem component='a' href='#courses/sewing' onClick={props.toggle_popper}>
            <Typography variant='body1'>
              <T value='courses.naehkurs' />
            </Typography>
          </ListItem>
          <ListItem component='a' href='#courses/topics' onClick={props.toggle_popper}>
            <Typography variant='body1'>
              <T value='courses.themenkurs' />
            </Typography>
          </ListItem>
        </List>
      </Popper>
    </AppBar >
  )
}
