import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from '../../utils/actions'
import { Translate as T } from 'react-redux-i18n'
import { withFirebase } from '../../utils/firebase'
import Loader from '../../templates/Loader'
import Text from '../../templates/Text'
import {
  Grid,
  Typography,
} from '@material-ui/core'

import CourseSewingDates from './elements/CourseSewingDates'


class CourseSewing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: null,
      courses: null,
    }
  }

  componentDidMount() {
    this.props.firebase.getStatic({ key: 'sewing' })
      .then((data) => {
        this.setState({ data })
      })
      .then(() => this.props.firebase.getCourses('sewing'))
      .then((courses) => {
        this.setState({
          loading: false,
          courses
        })
      })
      .catch((error) => this.props._setError(error))
  }

  render() {
    const { data, courses, loading } = this.state

    if (!data) { return (<Loader />) }

    return (
      <div className='app_content'>
        <Grid container className='app_padding'>
          <Grid item xs={12}>
            <Text text={data.text} />
            <br /><br />
          </Grid>
          {loading ? <Loader /> :
            <Grid item xs={12}>
              <Typography variant='h5'><T value='courses.course_dates' /></Typography><br />
              {courses.map((course) => (<CourseSewingDates key={course._id} course={course} />))}
            </Grid>
          }
        </Grid>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error))
})

export default withFirebase(connect(null, mapDispatchToProps)(CourseSewing))