import React from 'react'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n';
import { Button } from '@material-ui/core'


const LanguageSelect = function (props) {
  const { locale } = props
  const _handleSetLocale = function (locale) {
    const hash = window.location.hash
    props._setLocale(locale)
      .then(() => {
        window.localStorage.setItem('_lang', locale)
        window.location.hash = hash.split("#/").reverse()[0]
      })
  }

  return (
    <div style={{ width: '100%', textAlign: 'right' }}>
      <Button style={{ minWidth: '34px' }} color='secondary' onClick={() => _handleSetLocale('de')} disabled={locale === 'de'}>DE</Button>
      <Button style={{ minWidth: '34px' }} color='secondary' onClick={() => _handleSetLocale('fr')} disabled={locale === 'fr'}>FR</Button>
    </div>
  )
}



const mapStateToProps = state => ({
  locale: state.i18n.locale
})
const mapDispatchToProps = dispatch => ({
  _setLocale: locale => {
    return new Promise((resolve, reject) => {
      dispatch(setLocale(locale))
      resolve()
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect)