import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from '../../utils/actions'
import { Translate as T } from 'react-redux-i18n'
import { withFirebase } from '../../utils/firebase'
import Loader from '../../templates/Loader'
import Text from '../../templates/Text'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Hidden,
  Button,
  Typography
} from '@material-ui/core'


class Boerse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      dates: null,
      data: null
    }
  }

  componentDidMount() {
    this.props.firebase.getStatic({ key: 'home', subkey: 'boerse' })
      .then((data) => this.setState({ data }))
      .then(() => this.props.firebase.getSeasonsBeorse())
      .then((dates) => this.setState({
        loading: false,
        dates: dates
      }))
      .catch((error) => this.props._setError(error))
  }

  _getDatesString(date) {
    const { locale } = this.props
    date = new Date(date)
    return date.toLocaleDateString(locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

  render() {
    const { data, dates, loading } = this.state

    if (!data) { return (<Loader />) }

    return (
      <div className='app_content'>
        <div className='app_padding'>
          <Text text={data.text} />

          <br /><br />
          {dates ?
            <div>
              {dates.length > 0 ?
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell><T value='courses.date' /></TableCell>
                      <Hidden xsDown={true}>
                        <TableCell align="right"><T value='boerse.timeframe' /></TableCell>
                      </Hidden>
                      <Hidden xsDown={true}>
                        <TableCell align="right"><T value='courses.price' /></TableCell>
                      </Hidden>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dates.map((date) => (
                      <TableRow key={date._id}>
                        <TableCell component="th" scope="row">
                          {this._getDatesString(date.date)}
                          <Hidden smUp={true}><br />{date.timeframe.from} - {date.timeframe.to} <T value='app.time_unit' /></Hidden>
                          <Hidden smUp={true}><br /><T value='app.price_unit' />{' ' + date.price}</Hidden>
                        </TableCell>
                        <Hidden xsDown={true}>
                          <TableCell align="right">{date.timeframe.from} - {date.timeframe.to} <T value='app.time_unit' /></TableCell>
                        </Hidden>
                        <Hidden xsDown={true}>
                          <TableCell align="right"><T value='app.price_unit' />{' ' + date.price}</TableCell>
                        </Hidden>
                        <TableCell align="right">
                          <Button
                            color='secondary'
                            onClick={() => {
                              window.location.hash = 'boerse/signup/boerse/' + date._id
                            }}
                          >
                            <T value='user.signup' />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                :
                <div style={{ textAlign: 'center' }}>
                  <br /><br />
                  <Typography variant='subtitle1'><T value='boerse.no_available_dates' /></Typography>
                  <br /><br />
                </div>
              }
            </div>
            :
            <Loader />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locale: state.i18n.locale
})
const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error))
})

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(Boerse))