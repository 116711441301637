import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';


export default function CustomDrawer(props) {

  const _onClick = (value) => {

    window.location.hash = value
    props.toggle_drawer()
  }

  return (
    <SwipeableDrawer
      open={props.drawer_open}
      onClose={props.toggle_drawer}
      onOpen={props.toggle_drawer}
    >
      <List className='drawer'>
        <ListItem button onClick={(e) => _onClick('home')}>
          <ListItemText primary={<T value={'home.title'} />} />
        </ListItem>
        <ListItem button onClick={(e) => _onClick('home/courses')}>
          <ListItemText primary={<T value="courses.title" />} />
        </ListItem>
        <List component="div" disablePadding>
          <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('courses/sewing')}>
            <ListItemText primary={<T value="courses.naehkurs" />} />
          </ListItem>
          <ListItem button className='drawer_item_nested' onClick={(e) => _onClick('courses/topics')}>
            <ListItemText primary={<T value="courses.themenkurs" />} />
          </ListItem>
        </List>
        {[
          ['atelier', 'home/atelier'],
          ['boerse', 'boerse'],
          ['aboutme', 'aboutme'],
          ['gallery', 'gallery'],
          ['contact', 'contact']
        ].map((item) => (
          <ListItem key={item} button onClick={(e) => _onClick(item[1])}>
            <ListItemText primary={<T value={item[0] + '.title'} />} />
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  )
}

/*
        <Divider />
        <ListItem button>
          <ListItemText primary={<T value="user.login" />} />
        </ListItem>*/